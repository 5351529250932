import axios, { AxiosInstance } from 'axios';
import {
  UploadedPart,
  UploadFileUrls,
} from '../../contexts/UploaderContext/uploadFileModel';
import { PostProjectRequest } from './model/postProjectRequest';
import { ProjectFileType } from './model/projectEnums';
import { FileUrlResponse } from '../files/fileUrlResponse';
import {
  ProjectContractResponse,
  ProjectFileDetails,
  ProjectFiles,
  ProjectResponse,
  ProjectSummary,
  ProyectCalendarResponse,
} from './model/projectResponse';
import { PutProjectRequest } from './model/putProjectRequest';
import { PutFileRequest } from '../files/putFileRequest';
import {
  ProjectBookFilters,
  ProjectCalendarFilters,
  ProjectFilters,
} from './model/projectFilters';
import { BookFileUrlResponse } from './model/bookFileUrlResponse';

const API_URL = process.env.REACT_APP_API_URL;
const PROJECTS_URL = `${API_URL}/projects`;
const PROJECT_BY_ID = (id: string, rest = '') => `${PROJECTS_URL}/${id}${rest}`;
const PROJECT_SUMMARY_BY_ID = (id: string) => PROJECT_BY_ID(id, '/summary');
const UPLOAD_FILE_URL = `/uploadFile`;
const PROJECT_FILES_URL = `/files`;
const PROJECT_FILE_BY_ID_AND_TYPE = (id: string, type: ProjectFileType) =>
  `${PROJECTS_URL}/${id}/file/${type}`;

export async function getProjects(
  axiosInstance: AxiosInstance = axios.create(),
  filters?: ProjectFilters,
): Promise<ProjectResponse[]> {
  const response = await axiosInstance.get(PROJECTS_URL, { params: filters });
  return response.data;
}

export async function getProject(
  id: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ProjectResponse> {
  const response = await axiosInstance.get(PROJECT_BY_ID(id));
  return response.data;
}

export async function getProjectSummary(
  id: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ProjectSummary> {
  const response = await axiosInstance.get(PROJECT_SUMMARY_BY_ID(id));
  return response.data;
}

export async function postProject(
  project: PostProjectRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ProjectResponse> {
  const response = await axiosInstance.post(PROJECTS_URL, project);
  return response.data;
}

export async function putProject(
  id: string,
  project: PutProjectRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ProjectResponse> {
  const response = await axiosInstance.put(PROJECT_BY_ID(id), project);
  return response.data;
}

export async function getUploadProjectFileUrls(
  id: string,
  type: ProjectFileType,
  filename: string,
  parts: number,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<UploadFileUrls> {
  const response = await axiosInstance.post(
    PROJECT_BY_ID(id, UPLOAD_FILE_URL),
    {
      name: filename,
      type,
      parts,
    },
  );
  return response.data;
}

export async function completeUploadProjectFile(
  id: string,
  type: ProjectFileType,
  key: string,
  uploadId: string,
  parts: UploadedPart[],
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ProjectFileDetails> {
  const response = await axiosInstance.put(PROJECT_BY_ID(id, UPLOAD_FILE_URL), {
    key,
    type,
    uploadId,
    parts,
  });
  return response.data;
}

export async function getProjectFileUrl(
  id: string,
  type: ProjectFileType,
  filters?: ProjectBookFilters,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<FileUrlResponse | BookFileUrlResponse> {
  const response = await axiosInstance.get(
    PROJECT_FILE_BY_ID_AND_TYPE(id, type),
    {
      params: { ...filters },
    },
  );
  return response && response.data;
}

export async function getProjectFiles(
  projectId: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ProjectFiles> {
  const response = await axiosInstance.get(
    PROJECT_BY_ID(projectId, PROJECT_FILES_URL),
  );
  return response.data;
}

export async function updateProjecFileInfo(
  id: string,
  type: ProjectFileType,
  update: PutFileRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ProjectFileDetails> {
  const response = await axiosInstance.put(
    PROJECT_FILE_BY_ID_AND_TYPE(id, type),
    update,
  );
  return response.data;
}

export async function deleteProjectFile(
  id: string,
  type: ProjectFileType,
  fileId?: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ProjectFileDetails> {
  const response = await axiosInstance.delete(
    PROJECT_FILE_BY_ID_AND_TYPE(id, type),
    {
      params: { fileId },
    },
  );
  return response.data;
}

export async function getProjectCalendar(
  id: string,
  axiosInstance: AxiosInstance = axios.create(),
  filters: ProjectCalendarFilters,
): Promise<ProyectCalendarResponse> {
  const response = await axiosInstance.get(PROJECT_BY_ID(id, '/calendar'), {
    params: filters,
  });
  return response.data;
}

export async function getProjectContractInfo(
  id: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ProjectContractResponse> {
  const response = await axiosInstance.get(PROJECT_BY_ID(id, '/contract'));
  return response.data;
}
