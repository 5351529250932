import EventEmitter from 'eventemitter3';
import { useEffect } from 'react';
import { createCtx } from 'src/utils/context';
import { WithChildrenProps } from 'src/utils/react';

const eventEmitter = new EventEmitter();

type EventsContextType = {
  eventEmitter: EventEmitter;
};

export const [EventsContext, CurrentEventsProvider] =
  createCtx<EventsContextType>();

export const useSub = (
  events: string | string[],
  callback: (...args: any[]) => any,
  dependencies?: any[],
) => {
  const unsubscribe = () => {
    if (Array.isArray(events)) {
      events.forEach((e) => {
        eventEmitter.off(e, callback);
      });
    } else {
      eventEmitter.off(events, callback);
    }
  };

  useEffect(() => {
    if (Array.isArray(events)) {
      events.forEach((e) => {
        eventEmitter.on(e, callback);
      });
    } else {
      eventEmitter.on(events, callback);
    }
    return unsubscribe;
  }, [dependencies]);

  return unsubscribe;
};

export const EventsProvider = ({ children }: WithChildrenProps) => {
  return (
    <CurrentEventsProvider value={{ eventEmitter }}>
      {children}
    </CurrentEventsProvider>
  );
};

export const useEvents = () => {
  return EventsContext();
};
